


























import Vue from "vue";
import EditProfile from "@/app/components/sections/profile/EditProfile.vue";
import pageHeader from "@/shared-components/BaPageHeader.vue"
import ProfileImage from "@/app/components/sections/profile/ProfileImage.vue";
import Signature from "@/app/components/sections/profile/Signature.vue";
import {mapActions} from "vuex";

export default Vue.extend({
  components: {
    EditProfile,
    ProfileImage,
    pageHeader,
    Signature
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchProfile: "auth/fetchProfile",
    }),
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchProfile()
    } catch (e) {
      this.$bvToast.toast('', {
        title: "Unable to fetch profile!",
        variant: "error",
        solid: true,
      });
    }
    this.isLoading = false
  }
});
