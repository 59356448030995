



















































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { formatDate } from "@/utils/helpers";
import { SETTINGS } from "@/app/routes/endpoints";

export default Vue.extend({
    name: "EditProfile",
    data() {
        return {
            loading: false,
            editProfileInfo: false,
            formFields: [
                {
                    key: "firstName",
                    label: "First Name",
                    type: "text",
                    disabled: false,
                },
                {
                    key: "lastName",
                    label: "Last Name",
                    type: "text",
                    disabled: false,
                },
                {
                    key: "phoneNumber",
                    label: "Phone",
                    type: "phone",
                    disabled: false,
                },
                {
                    key: "email",
                    label: "Email",
                    type: "email",
                    disabled: true,
                },
            ],
            profile: {
                profileImgId: "",
                firstName: "Sample",
                lastName: "Tester",
                phoneNumber: "09019836127",
                email: "test@gmail.com",
            },
        };
    },
    methods: {
        formatDate,
        ...mapActions({
            updateProfile: "auth/updateProfile",
            fetchProfile: "auth/fetchProfile",
        }),
        ...mapActions("users", ["setProfileState"]),
        async goToNextStep() {
            const currentStep = Number(this.$route.query["onboarding-step"]);
            if (currentStep) {
                this.setProfileState(true)
            }
        },
        toggleProfileInfo() {
            this.editProfileInfo = !this.editProfileInfo;
        },
        async saveProfile() {
            this.loading = true;
            const payload = { ...this.profile, email: this.profileData.email, profileImgId: this.profileData?.profileImgId };
            try {
                const res = await this.updateProfile(payload);
                this.$bvToast.toast(res.data.message, {
                    title: "Profile Update Successful!",
                    variant: "success",
                    solid: true,
                });
                this.editProfileInfo = false;
                await this.fetchProfile()
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to update profile. Try again",
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.loading = false;
            }
        },
        setUserInfo() {
            this.profile = { ...this.profile, ...this.profileData, profileImgId: this.profileData?.profileImgId };
        },
    },
    validations(): any {
        return {
            profile: {
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
                phoneNumber: {
                    required,
                },
                email: {
                    required,
                    email,
                },
            },
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
    },
    mounted() {
        this.setUserInfo();
    },
});
